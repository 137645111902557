import moment from 'moment'
export default {
  methods: {
    async ifCurrentUser2(custom = false){
      if (this.current_user){
        return this.current_user
      }else{
        this.axios.get("user").then((resp)=> {
          console.log("rest", resp)
        }).catch(_=> {
          console.log("dasda")
          this.current_user = false
        })
      }
    },
    where(query){
      return Object.entries(query).map(([k,v])=>{
        if (!v || v.length === 0) return ''
        if (!(v instanceof Array)) v = [v]
        return `${k}:${v.join('|')}`
      }).join(',')
    },
    env(){ return import.meta.env.MODE },
    keymasterAccessFor(name){
      return import.meta.env.MODE !=='production'|| this.keymaster[name] === 'pass'
    },
    // Use navigate instead of page directly
    // for future page animations or similar features
    navigate(url, reload=true){
      let    uri = url.toString()
      const  loc = window.location
      const base = `${loc.protocol}//${loc.host}`

      if (uri.startsWith(base)) uri = uri.replace(base, "")
      if (this.$route.fullPath === uri){
        if (reload) this.reload()
      }else{
        return this.$router.push({ path: uri })
      }
    },
    refresh(url){ this.$router.push(`${url}?ux=${new Date().getTime()}`) },
    redirectToBackUrl(base){
      const newPath = `/${base}`
      // const current_path = `${location.pathname}${location.search}${location.hash}`
      if (newPath === this.$route.path) return
      this.$router.push(`${newPath}?back=${encodeURIComponent(this.$route.fullPath)}`).catch(_=>{})
    },
    current_page(){ return parseInt(this.$route.query.page || 1) },
    currencySign(obj){
      const c = this.currency(obj)
      return c.symbol ? c.symbol : c.code
    },
    moment(time, format = "DD/MM/YYYY"){
      return this.parseMoment(time).format(format)
    },
    parseMoment(time){
      return moment(time).tz(this.current_shop?.time_zone||"UTC")
    },
    fullNumber(num){
      if (!num) return
      const float = parseFloat(num)
      return float.toString() === num ? num.toFixed(2) : float
    },
  }
}